/* Fonts */
@font-face {
  font-family: 'Gibson';
  font-weight: normal;
  font-style: normal;
  src: url('./Assets/Fonts/Gibson.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gibson', sans-serif;
  font-size: 0.9375rem; /* 15px */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7b8389;
  background: #f5f8fa;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #7b8389 !important;
}

a:not(.active) {
  border-bottom: none;
}

/* Alignment */
.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}
/* text styling */
.capitalized {
  text-transform: capitalize !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.strike-through {
  text-decoration: line-through !important;
  color: black !important;
}

/* Show cursor on hover */
.pointer-cursor:hover {
  cursor: pointer;
}

/* Grid */
.row {
  margin-left: 0px;
  margin-right: 0px;
}

/* Input fields */
label {
  color: #7b8389 !important;
  font-size: 0.9375rem !important;
}

.form-control {
  background: #eaeff3 !important;
  color: #7b8389 !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 0.9375rem !important;
}

.input-group .input-group-text {
  border: none !important;
}

.input-group-prepend {
  width: 40px;
}

.input-group-prepend svg {
  margin: 0 auto;
  margin-top: 10px;
  color: #666;
}

/* Input field placeholder */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

/* Buttons */
.btn {
  height: 40px !important;
  width: 100%;
  padding: 10px;
  text-transform: capitalize;
  border: none !important;
  border-radius: 5px !important;
  font-size: 14px;
  background: #bee5e2;
  box-shadow: 1px 11px 15px -13px #bee5e2 !important;
}

/* secondary btn */
.secondary-btn {
  background-color: #9ca3a7;
}

.secondary-btn:hover {
  background-color: #5a6268;
}

/* Modal */
.modal-header {
  padding: 0.75rem;
  border-bottom: 1px solid #f2f2f2 !important;
}

.modal-title {
  font-size: 1.05em;
  text-transform: capitalize;
  letter-spacing: 2px;
}

@media (min-width: 576px) {
  .large-modal {
    max-width: calc(100% - 50px);
  }
}

@media (min-width: 576px) {
  .medium-modal {
    max-width: calc(80%);
  }
}

/* React Select (dropdown) styling */

.select-styling div {
  border-radius: 5px !important;
  color: #7b8389 !important;
  background: #eaeff3 !important;
  border: none !important;
  font-size: 0.9375rem !important;
}

.select-styling-padded {
  padding: 1rem;
}
/* Form phone number */

.react-tel-input input[type='tel'],
.react-tel-input input[type='text'] {
  width: 100% !important;
  padding-left: 48px !important;
  background: #eaeff3 !important;
  color: #7b8389 !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 0.9375rem !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

/* dropdown menu */
.dropdown-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.avatar-dropdown-menu-item {
  padding: 6px 16px 6px 16px;
  color: #7b8389;
  font-size: 14px;
}

.avatar-dropdown-menu-item:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

/* search input */
.searchContainer {
  display: inline-flex;
  width: 95%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: hidden;
}

.searchIcon {
  position: relative;
  left: 0.5rem;
  align-self: center;
}

.searchBox {
  border: none !important;
  position: relative;
  left: 0.63rem;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #7bb5b0 !important;
}

/* form input error  */

.input-group-error {
  width: 50%;
  top: 10px;
  position: relative;
  margin: auto;
  text-align: center;
}

.input-group-error > span {
  position: relative;
  font-weight: 200 !important;
  font-size: 0.9375rem;
  color: red;
}

.input-error {
  border: 1px solid red !important;
}

/* Display types */
.display-flex {
  display: flex;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.align-items-center {
  display: flex;
  align-items: center;
}
